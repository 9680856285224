<template>
    <div class="p-col-12 p-card relative customPad">
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <router-view :loading="loading" @loadingChange="loadingChange" />
    </div>
</template>

<script>
export default {
    name: 'index',

    data: () => ({
        loading: true,
    }),

    activated() {
        this.$emit('loadingChange');
    },

    methods: {
        loadingChange(payload = false) {
            this.loading = payload;
        }
    }
};
</script>

<style lang='scss' scoped>
.customPad {
    padding: 20px 16px;
}

.relative {
    position: relative;
}

.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
</style>
